.overlay {
  height: 100vh;
  overflow: hidden;
  padding: 0; }
  .overlay .modal {
    background: none;
    height: 100vh;
    width: 100vw;
    padding: 0; }
    .overlay .modal .popup {
      width: auto;
      height: auto;
      position: absolute;
      top: 12.5%;
      left: 50%;
      -webkit-transform: translate(-50%);
              transform: translate(-50%); }
      .overlay .modal .popup > img {
        width: 75vw; }
      .overlay .modal .popup > div {
        position: absolute;
        top: 2.1%;
        left: 0%;
        height: 90%;
        width: 100%; }
        .overlay .modal .popup > div .top {
          height: 17.5%;
          display: flex;
          align-items: center;
          padding-left: 5%; }
          .overlay .modal .popup > div .top > button {
            padding: 1%;
            background-color: #1A1A18;
            outline: none;
            border: none;
            width: 7.7%;
            position: absolute;
            right: 1%;
            top: 0%;
            display: flex;
            justify-content: center;
            align-items: center; }
            .overlay .modal .popup > div .top > button img {
              width: 100%; }
          .overlay .modal .popup > div .top > span {
            color: #ffffff;
            font-size: 2.5vw; }
        .overlay .modal .popup > div ol {
          overflow: auto;
          width: 100%;
          height: 80%;
          color: #ffffff;
          padding: 0% 5% 2% 8%;
          margin-top: 2%;
          font-size: 2vw; }
    .overlay .modal .button {
      display: none; }

* {
  box-sizing: border-box; }

body {
  width: 100%;
  margin: 0; }

.home {
  background: url(/static/media/warfare_bg.fd224328.jpg) no-repeat center top;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: relative; }
  .home .container {
    width: 50%;
    height: 50%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center; }
    .home .container > div {
      width: 100%;
      margin: 0 0 10vh 0; }
    .home .container .codm-logo {
      margin-top: 12.5vh;
      display: flex;
      justify-content: center; }
      .home .container .codm-logo img {
        width: 60%; }
    .home .container .barnomor {
      position: relative; }
      .home .container .barnomor > img {
        width: 100%; }
      .home .container .barnomor > div {
        width: 100%;
        height: 50%;
        position: absolute;
        top: 65%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4vh; }
    .home .container .btn-group {
      display: flex;
      justify-content: space-around; }
      .home .container .btn-group > button {
        outline: none;
        width: 35%;
        background: none;
        padding: 0;
        border: none; }
        .home .container .btn-group > button:active img {
          width: 98%; }
        .home .container .btn-group > button img {
          width: 100%; }

.style_wp_pop__v8kOa {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  overflow: hidden;
}

.style_wp_content__2MpCv {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.style_wp_pop_img__3ZH4M {
  width: 25vw;
  height: 16vw;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-animation: style_rotateA__1t1eo 1.5s ease infinite alternate;
          animation: style_rotateA__1t1eo 1.5s ease infinite alternate;
}

.style_wp_tips__kAF7B {
  width: 60%;
  font-size: 2vw;
  color: #FFFFFF;
  text-align: center;
  margin: 6vw auto 0;
}

@-webkit-keyframes style_rotateA__1t1eo {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  70% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

@keyframes style_rotateA__1t1eo {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  70% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

@media screen and (max-width: 820px) and (orientation: portrait) {
  .style_portrait__hvrar {
    display: block; 
  } 
}

@media screen and (max-width: 820px) and (orientation: landscape) {
  .style_landscape__2pMsW {
    display: block;
  } 
}

@media screen and (min-width: 820px) and (orientation: landscape) {
  .style_desktop_landscape__3AP5n {
    display: block; } }
.style_overlay__UvXmr{
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	text-align: center;
	display: none;
	top: 0;
	left: 0;
}

.style_img__2ouvf{
	position: relative;
	width: 33%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.style_active__2gWoK{
	display: block;
}
