.overlay {
  height: 100vh;
  overflow: hidden;
  padding: 0;
  .modal {
    background: none;
    height: 100vh;
    width: 100vw;
    padding: 0;
  .popup {
      width: auto;
      height: auto;
      position: absolute;
      top: 12.5%;
      left: 50%;
      transform: translate(-50%);
      
      >img {
        width: 75vw;
      }
      >div {
        position: absolute;
        top: 2.1%;
        left: 0%;
        height: 90%;
        width: 100%;
        .top {
          height: 17.5%;
          display: flex;
          align-items: center;
          padding-left: 5%;
          >button {
            padding: 1%;
            background-color: #1A1A18 ;
            outline: none;
            border: none;
            width: 7.7%;
            position: absolute;
            right: 1%;
            top: 0%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
            }
          }
          >span {
            color: #ffffff;
            font-size: 2.5vw;
        }
        }
        ol {
          overflow: auto;
          width: 100%;
          height: 80%;
          color: #ffffff;
          padding: 0% 5% 2% 8%;
          margin-top: 2%;
          font-size: 2vw;
        }
      }
    }
    .button {
      display: none;
    }
  }
}