@import'./_popup';

* {
	box-sizing: border-box;
}

body {
	width: 100%;
	margin: 0;
}

.home {
	background: url('../img/warfare_bg.jpg') no-repeat center top;
	background-size: cover;
	height: 100vh;
	width: 100vw;
	position: relative;
	.container {
		width: 50%;
		height: 50%;
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		>div {
			width: 100%;
			margin: 0 0 10vh 0;
		}
		.codm-logo {
			margin-top: 12.5vh;
			display: flex;
			justify-content: center;
			img {
				width: 60%;
			}
		}
		.barnomor {
			position: relative;
			>img {
				width: 100%;
			}
			>div {
				width: 100%;
				height: 50%;
				position: absolute;
				top: 65%;
				transform: translateY(-50%);
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 4vh;
			}
		}
		.btn-group {
			display: flex;
			justify-content: space-around;
			>button {
				outline: none;
				width: 35%;
				background: none;
				padding: 0;
				border: none;
				&:active {
					img {
						width: 98%;
					}
				}
				img {
					width: 100%;
				}
				
			}
		}
	}
}